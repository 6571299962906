import IconHeartOutline from '@haiper/icons-svg/icons/outline/heart.svg'
import IconHeartSolid from '@haiper/icons-svg/icons/solid/heart.svg'
import IconChat from '@haiper/icons-svg/icons/outline/chat.svg'
import { cls, preventDefaultAndStopPropagation } from '@/utils'
import ButtonGroup, { ButtonGroupItem } from '../button-group'
import { useCallback, useMemo } from 'react'
import { useCreationOutputLikeCache, useSpotlightLikeCache } from '@/hooks/useLikeCache'
import { isNil } from 'lodash-es'
import { useCreationOutputCommentcache, useSpotlightCommentcache } from '@/hooks/useCommentCache'
import useAuth from '@/hooks/useAuth'
import { useSetAtom } from 'jotai'
import { loginDialogOpenAtom } from '@/atoms'
import { postCollectStateToCreationById } from '@/service/creation.service'
import { likeSpotlight } from '@/service/spotlight.service'
import { useRouter } from 'next/navigation'
import useIsLogin from '@/hooks/useIsLogin'

export interface WorkActionsProps {
  className?: string
  data: haiper.Work
  commits?: haiper.Commits
  commentCount?: number
}

export default function WorkActions({ data, className, commits, commentCount }: WorkActionsProps) {
  const router = useRouter()

  const handleOpenComment = useCallback(
    (e?: any) => {
      preventDefaultAndStopPropagation(e)

      switch (data?.work_type) {
        case 'creation_video':
          router.push(`/creation/${data.work_id}?type=output`)
          break
        case 'creation_image':
          router.push(`/creation/${data.work_id}?type=output`)
          break
        case 'spotlight':
          router.push(`/spotlight/${data.work_id}`)
          break
        default:
          break
      }
    },
    [data, router],
  )

  const outputId = data?.work_type === 'spotlight' ? '' : data?.work_id ?? ''
  const spotlightId = data?.work_type === 'spotlight' ? data?.work_id ?? '' : ''

  const outputLikeCache = useCreationOutputLikeCache(outputId)
  const spotlightLikeCache = useSpotlightLikeCache(spotlightId)

  const likeCache = data?.work_type === 'spotlight' ? spotlightLikeCache : outputLikeCache

  const _favorited = commits?.is_like ?? false
  const _favoriteCount = commits?.likes_count ?? 0
  const favorited = likeCache.liked ?? _favorited
  const favoriteCount =
    _favoriteCount + (isNil(likeCache.liked) || likeCache.liked === _favorited ? 0 : likeCache.liked == false ? -1 : 1)

  const outputCommentCache = useCreationOutputCommentcache(outputId)
  const spotlightCommentCache = useSpotlightCommentcache(spotlightId)
  const commentCache = data?.work_type === 'spotlight' ? spotlightCommentCache : outputCommentCache

  const realCommentCount = commentCache?.count ?? commentCount

  const isLogin = useIsLogin()

  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)
  const showAuthDialog = useCallback(() => {
    setLoginDialogOpen(true)
  }, [setLoginDialogOpen])

  const handleFavorite = useCallback(async () => {
    if (!isLogin) {
      showAuthDialog()
      return
    }
    const newState = !favorited
    likeCache.toggle(newState)
    try {
      if (data?.work_type === 'spotlight') {
        await likeSpotlight({
          spotlight_id: spotlightId,
          is_like: newState,
        })
      } else {
        await postCollectStateToCreationById({
          output_id: outputId,
          is_collect: newState,
        })
      }
    } catch (error) {
      likeCache.toggle(!newState)
    }
  }, [isLogin, showAuthDialog, outputId, favorited, likeCache, spotlightId, data])

  const rightButtons: ButtonGroupItem[] = useMemo(() => {
    return [
      {
        key: 'favorite',
        icon: favorited ? IconHeartSolid : IconHeartOutline,
        className: '',
        label: String(favoriteCount || ''),
        visible: true,
        tooltip: { content: 'Like' },
        iconClassname: favorited ? 'text-border-critical-hover' : '',
        onClick: handleFavorite,
      },
      {
        key: 'comment',
        icon: IconChat,
        label: String(realCommentCount || ''),
        className: '',
        tooltip: { title: 'Comment', content: '' },
        visible: true,
        onClick: handleOpenComment,
      },
    ]
  }, [handleFavorite, handleOpenComment, favorited, realCommentCount, favoriteCount])

  return (
    <div className={cls('', className)}>
      <ButtonGroup
        variant='transparent'
        className='gap-1 overflow-x-hidden'
        size='sm'
        items={rightButtons}
        iconWrapClassname={cls('size-8 w-max')}
        itemClassName={cls('bg-surface hover:bg-surface-hover active:bg-surface-hover')}
        iconClassname='size-5'
      />
    </div>
  )
}
