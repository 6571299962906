import IconX from '@haiper/icons-svg/icons/band/x.svg'
import IconInstagram from '@haiper/icons-svg/icons/band/instagram.svg'
import IconYoutube from '@haiper/icons-svg/icons/band/youtube.svg'
import IconTiktok from '@haiper/icons-svg/icons/band/tiktok.svg'
import IconXiaohongshu from '@/public/assets/xiaohongshu.svg'
import IconWebsite from '@haiper/icons-svg/icons/outline/globus.svg'
import IconBrush from '@haiper/icons-svg/icons/outline/brush.svg'
import IconText from '@haiper/icons-svg/icons/outline/ai-text.svg'
import IconImage from '@haiper/icons-svg/icons/outline/image-square-2-mountains.svg'
import { CameraMovement, CreationModeEnum, CreditSpendSku, ModelVersion } from '@/types'
import IconClockAdd from '@haiper/icons-svg/icons/outline/clock-add.svg'
import IconFeatures from '@haiper/icons-svg/icons/outline/features.svg'
import UsecaseLogoSample from '@/public/assets/logo-animation.svg'
import { isProduction, isStage } from '@/utils/base'

export const firebaseBucketPrefix = 'gs://internal-testing-tool.appspot.com'

export const availableModels: Record<string, string> = {
  long_masks: 'https://static2.haiper.ai/public/s/sam_long_masks.onnx',
  quantized: 'https://static2.haiper.ai/public/s/sam_quantized.onnx',
  long_uncertain: 'https://static2.haiper.ai/public/s/sam_long_uncertain.onnx',
  vit_b: 'https://static2.haiper.ai/public/s/sam_onnx_quantized_vit_b_01ec64.onnx',
  vit_h: 'https://static2.haiper.ai/public/s/sam_onnx_quantized_vit_h_4b8939.onnx',
  vit_l: 'https://static2.haiper.ai/public/s/sam_onnx_quantized_vit_l_0b3195.onnx',
}

export const LINKS = {
  privacy: 'https://static2.haiper.ai/public/acceptable-use-policy-11072024.html',
  faq: 'https://book.haiper.ai/haiper-video-generator/faq',
  manual: 'https://book.haiper.ai/haiper-video-generator/',
  contact: '/contact',
  careers: '/careers',
  about: '/about',
  termsOfUse: 'https://static2.haiper.ai/public/terms-of-use-11072024.html',
  getInTouch: 'mailto:moderation@haiper.ai',
  billing: 'mailto:billing@haiper.ai',
  // apiService: 'https://yxc3mmw1e9.larksuite.com/docx/RhbxdbSaZoijVgxw9PFulzVOsTc',
  apiService: 'https://docs.haiper.ai/api-reference/',
  apiServiceHowTo: 'https://docs.haiper.ai/api-reference/authentication',
  info: 'mailto:info@haiper.ai',
}

export const DEFAULT_PAGE_SIZE = 40
export const AMPLITUDE_USER_ID_KEY = 'amplitude-user-id'
export const AMPLITUDE_USER_CREATE_TIME_KEY = 'amplitude-user-create-time'

export const PROD_HOST = 'https://haiper.ai'
export const TEST_HOST = 'https://visionbrew.haiper.ai'
export const CACHE_AUTH_REDIRECT_URL = 'auth.redirect_url'
export const CACHE_AUTH_SOURCE = 'auth.source'

export const SITE_NAME = 'Haiper AI'
export const SITE_TITLE = 'Haiper - AI Video Generator'
export const SITE_DESCRIPTION = 'AI powered video creation platform'

export const DEFAULT_COOL_DOWN_TIME = 20
export const NO_OUTLINE_STYLE = 'focus:outline-none focus:outline-0 focus-visible:outline-none focus-visible:outline-0'

export const DEFAULT_AVATAR = 'https://assets.haiper.ai/webapp/images/default-avatar.png'

export const ALLOWED_IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.webp']
export const ALLOWED_VIDEO_EXTENSIONS = ['.mp4', '.mkv', '.mov']

export const SOCIAL_ENTRIES = [
  {
    name: 'website',
    label: 'Website',
    Icon: IconWebsite,
  },
  {
    name: 'x',
    label: 'X (Twitter)',
    Icon: IconX,
  },
  {
    name: 'instagram',
    label: 'Instagram',
    Icon: IconInstagram,
  },
  {
    name: 'youtube',
    label: 'YouTube',
    Icon: IconYoutube,
  },
  {
    name: 'tiktok',
    label: 'TikTok',
    Icon: IconTiktok,
  },
  {
    name: 'xiaohongshu',
    label: 'Xiaohongshu',
    Icon: IconXiaohongshu,
  },
]

export const SELF_USER_ID = '-'

export const UTM_FIELDS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id', 'utm_name']

export const VALID_PARTERS: string[] = []

export const MEMBERSHIP_TIER_IDS = {
  FREE: 'L100',
  EXPLORER: 'L200',
  PRO: 'L300',
  ULTRA: 'L400',
  MAX: 'L500',
  UNLIMITED: '__UNLIMITED__',
  ENTERPRISE: '__ENTERPRISE__',
}

export const API_TIER_IDS = {
  ENTERPRISE_TRIAL: 'T100',
  ENTERPRISE_BASIC: 'T200',
  ENTERPRISE_CUSTOMIZED: 'T300',
}

export const HIGHEST_TIER_ID = MEMBERSHIP_TIER_IDS.MAX
export const PRO_TIER_ID = MEMBERSHIP_TIER_IDS.PRO
export const EXPLORER_TIER_ID = MEMBERSHIP_TIER_IDS.EXPLORER
export const FREE_TIER_ID = MEMBERSHIP_TIER_IDS.FREE

export const DEFAULT_CURRENCY = 'usd'

export const CACHE_KEYS = {
  ONBOARDING_DATA: 'onboarding_data',
  PREFERENCES: 'preferences',
  FOLLOW_US_DIALOG: 'dialog.follow-us-dialog',
  NEW_FEATURE_DIALOG: 'dialog.new-feature-readed-keys',
  ONBOARDING_SURVEY_TIME: 'onboarding_survey_time',
  READED_EVENTS: 'cache.readed-events',
  CHECKIN_DIALOG_LAST_DATE: 'dialog.checkin-dialog-last-date',
}

export const IDB_KEYS = {
  TEMPLATES: 'idb.templates',
}

export const inputType2Icon = {
  text: IconText,
  image: IconImage,
  video: IconBrush,
  extend: IconClockAdd,
  upscale: IconFeatures,
}

export const inputType2Color = {
  text: 'bg-green-600',
  image: 'bg-band-500',
  video: 'bg-yellow-600',
  extend: 'bg-blue-400',
  upscale: 'bg-red-500',
}

export const type2Icon: Record<string, any> = {
  extend: IconClockAdd,
  upscale: IconFeatures,
  template: UsecaseLogoSample,
}

export const type2Color: Record<string, string> = {
  extend: 'bg-blue-400',
  upscale: 'bg-red-500',
  template: 'bg-white border border-border rounded-full',
}

export const MAX_FILE_SIZE = 30 * 1024 * 1024
export const creationInputTranslateStyle = {
  transform: 'translate(-50%)',
}

export const CREATE_MODE_ALIAS: Record<ModelVersion, Partial<Record<CreationModeEnum, string>>> = {
  [ModelVersion.ONE]: {
    [CreationModeEnum.Repaint]: 'repainting/vid2vid',
    [CreationModeEnum.CreateHD]: 'generation/txt2vid',
    [CreationModeEnum.AnimateHD]: 'generation/img2vid',
    [CreationModeEnum.Extend]: 'extend/vid2vid',
    [CreationModeEnum.Upscale]: 'upscale/vid2vid',
    [CreationModeEnum.CreateImg]: 'generation/txt2img',
  },
  [ModelVersion.TWO]: {
    [CreationModeEnum.CreateHD]: 'gen2/txt2vid',
    [CreationModeEnum.AnimateHD]: 'gen2/img2vid',
    [CreationModeEnum.CreateImg]: 'gen2/txt2img',
  },
}

export const AFC_REQUIRE_SAME_RESOLUTION = false

export const CACHE_KEY_SUBSCRIPTION_REMIND = 'lastSubscriptionRemindTime'
export const DEFAULT_RESOLUTION = 720 // 720p
// export const DEFAULT_IMAGE_RESOLUTION = 1080 // 1080p
export const DEFAULT_IMAGE_RESOLUTION = 720

export const DISCORD_LINK = 'https://discord.gg/EUQvgt5dav'
export const X_LINK = 'https://x.com/HaiperGenAI'
export const TIKTOK_LINK = 'https://www.tiktok.com/@haiper.ai'
export const INSTAGRAM_LINK = 'https://www.instagram.com/haiper.ai/'

// export const globalLayoutPositionStyle = ''
export const sidebarWidthStyle = 'w-0 md:w-[66px] md:min-w-[66px] widescreen:w-[226px]'
export const sidebarOffsetStyle = 'md:pl-[66px] widescreen:pl-[226px]'
export const absoluteStyleWithoutSidebar = 'left-0 md:left-[67px] widescreen:left-[227px] md:right-4'

// export const DEFAULT_DURATION = 4

export const DEFAULT_TOOLTIP_DELAY_DURATION = 300
export const cameraMovementMap: Record<CameraMovement, string> = {
  none: 'None',
  zoom_in: 'Zoom In',
  zoom_out: 'Zoom Out',
  pan_left: 'Pan Left',
  pan_right: 'Pan Right',
  tilt_up: 'Tilt Up',
  tilt_down: 'Tilt Down',
}

export const DEFAULT_DURATION = 4

export const DEFAULT_SKUS: CreditSpendSku[] = [
  { id: 1, second: 2, amount: 0 },
  { id: 2, second: 4, amount: 0 },
  { id: 3, second: 8, amount: 40 },
]

export const TEMPLATE_SKUS: CreditSpendSku[] = [
  { id: 1, second: 2, amount: 0 },
  { id: 2, second: 4, amount: 0 },
  { id: 3, second: 8, amount: 0 },
]

// export const DISABLE_SAM = false
export const DISABLE_SAM = true

export const ASPECT_RATIO_MAP: Record<string, string> = {
  '9:16': '768x1344',
  '16:9': '1344x768',
  '3:4': '896x1152',
  '4:3': '1152x896',
  '1:1': '1024x1024',
  '21:9': '1536x640',
}

export const DEFAULT_ASPECT_RATIO = '16:9'
// export const DEFAULT_ASPECT_RATIO = '1:1'

export const MEMBERSHIP_FEATURES: Record<string, Array<{ name: string; included: boolean; tips?: string }>> = {
  [MEMBERSHIP_TIER_IDS.FREE]: [
    {
      name: '100 credits',
      included: true,
      tips: '100 credits when you register',
    },
    {
      name: '3 concurrent creations',
      included: true,
    },
    {
      name: 'Create Watermark-free images',
      included: true,
    },
    {
      name: 'No watermark-free videos',
      included: false,
    },
    {
      name: 'No commercial use',
      included: false,
    },
    {
      name: 'No private creation',
      included: false,
    },
  ],
  [MEMBERSHIP_TIER_IDS.EXPLORER]: [
    {
      name: '1500 credits per month',
      included: true,
      // tips: '1500 credits added to your account monthly, the credits won’t expire',
    },
    {
      name: 'Unlimited v1.5 video model creations',
      included: true,
      tips: '2s and 4s videos in 540p or 720p',
    },
    {
      name: '5 concurrent creations',
      included: true,
    },
    {
      name: 'Early access to new features',
      included: true,
    },
    {
      name: 'Create Watermark-free images',
      included: true,
    },
    {
      name: 'No watermark-free videos',
      included: false,
    },
    {
      name: 'No commercial use',
      included: false,
    },
    {
      name: 'No private creation',
      included: false,
    },
  ],
  [MEMBERSHIP_TIER_IDS.PRO]: [
    {
      name: '5000 credits per month',
      included: true,
      // tips: '5000 credits added to your account monthly, the credits won’t expire',
    },
    {
      name: 'Unlimited v1.5 video model creations',
      included: true,
      tips: '2s and 4s videos in 540p or 720p',
    },
    {
      name: '10 concurrent creations',
      included: true,
    },
    {
      name: 'Early access to new features (more features are coming soon)',
      included: true,
    },
    {
      name: 'Create Watermark-free images',
      included: true,
    },
    {
      name: 'Create watermark-free videos',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
  ],
  [MEMBERSHIP_TIER_IDS.ULTRA]: [
    {
      name: '17000 credits per month',
      included: true,
      // tips: '17000 credits added to your account monthly, the credits won’t expire',
    },
    {
      name: 'Unlimited v1.5 video model creations',
      included: true,
      tips: '2s and 4s videos in 540p or 720p',
    },
    {
      name: '15 concurrent creations',
      included: true,
    },
    {
      name: 'Early access to new features (more features are coming soon)',
      included: true,
    },
    {
      name: 'Create Watermark-free images',
      included: true,
    },
    {
      name: 'Create watermark-free videos',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
  ],
  [MEMBERSHIP_TIER_IDS.MAX]: [
    {
      name: '85000 credits per month',
      included: true,
      // tips: '85000 credits added to your account monthly, the credits won’t expire',
    },
    {
      name: 'Unlimited v1.5 video model creations',
      included: true,
      tips: '2s and 4s videos in 540p or 720p',
    },
    {
      name: '20 concurrent creations',
      included: true,
    },
    {
      name: 'Early access to new features (more features are coming soon)',
      included: true,
    },
    {
      name: 'Create Watermark-free images',
      included: true,
    },
    {
      name: 'Create watermark-free videos',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
  ],
  [MEMBERSHIP_TIER_IDS.UNLIMITED]: [
    {
      name: 'Include all Max plan features',
      included: true,
    },
    {
      name: 'Unlimited generations of v1 and v2 in relaxed mode',
      included: true,
    },
  ],
  [MEMBERSHIP_TIER_IDS.ENTERPRISE]: [
    {
      name: 'Creations on demand',
      included: true,
    },
    {
      name: 'More concurrent creations',
      included: true,
    },
    {
      name: 'Customized functions',
      included: true,
    },
    {
      name: 'Create Watermark-free images',
      included: true,
    },
    {
      name: 'Create Watermark-free videos',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
  ],
}

export const NEW_FEATURE_MODEL_2_1 = 'model-2.1'
export const NEW_FEATURE_TEMPLATE_OF_THE_DAY = 'totd-241031'

export const TEMPLATE_CREDIT_PRICE = 30

export const DEBUG_ID_PLAYGROUND_LAYOUT = 'debug.playground-layout'

export const SPOTLIGHT_BEST_PRACTICES = [
  {
    name: 'duration',
    icon: 'https://assets.haiper.ai/webapp/images/spotlight/watch.svg',
    title: '12s+',
    description: 'Your video should longer than 12 seconds',
  },
  {
    name: 'ai',
    icon: 'https://assets.haiper.ai/webapp/images/spotlight/robot.svg',
    title: 'AI Generated',
    description: 'The key shots of your video should be generated by AI',
  },
  {
    name: 'tools',
    icon: 'https://assets.haiper.ai/webapp/images/spotlight/magic.svg',
    title: 'Multi-tools',
    description: 'We encourage you to use Haiper\'s tools more',
  },
]
export const SHOW_V2V_MODEL_2 = !isProduction
// export const SHOW_V2V_MODEL_2 = true
// export const SHOW_V2V_MODEL_2 = false

export const SHOW_IMAGE_SUBMIT_BUTTON = false
export const SHOW_CAMERA_MOVEMENT_MODEL_2 = true

// const MAX_DURATION = 16
export const MAX_VIDEO_FILE_SIZE = 100 * 1024 * 1024 // 100MB
export const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024 // 5MB

export const IMAGE_ACCEPT = {
  'image/png': ALLOWED_IMAGE_EXTENSIONS,
  'image/jpeg': ALLOWED_IMAGE_EXTENSIONS,
  'image/webp': ALLOWED_IMAGE_EXTENSIONS,
}

export const VIDEO_ACCEPT = {
  'video/mp4': ALLOWED_VIDEO_EXTENSIONS,
  'video/x-matroska': ALLOWED_VIDEO_EXTENSIONS,
  'video/quicktime': ALLOWED_VIDEO_EXTENSIONS,
}

export const TEMPLATE_IDS = {
  OUTFIT: '675300e1151cb72fc60d8c72',
}

export const CLOTHING_TYPES = {
  TOP: 1,
  BOTTOM: 2,
  SET_OR_DRESS: 3,
  MULTIPLE_GARMENTS: 4,
}
// fixed virtual marketing event ids
export const EVENT_ID_CREATION_SHOWCASE = '674dd2a7e27dcae0f2a725ee'
export const EVENT_ID_SPOTLIGHT = '674dd30e883251fa65dd6e11'

export const MAGIC_DELIMITER = '&^_^&'
// export const USE_HAIPER_AUTH = !isProduction || isStage
export const USE_HAIPER_AUTH = true
